import "core-js/modules/es6.set.js";
import "core-js/modules/es6.string.iterator.js";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/web.dom.iterable.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.from.js";
import "core-js/modules/es6.array.map.js";
import "core-js/modules/es6.map.js";
import "core-js/modules/es6.array.find.js";
import "core-js/modules/es6.function.name.js";
import { AssetManagementCategoryType, MetricPeriodType, ResultsOpexBudgetRecordsDocument, ResultsOpexPropertiesDocument } from "~/graphql/generated/graphql";
import moment from "moment";
import { Operator } from "~/helpers/apollo/apollo-utility-helpers";
import { budgetValidRangeFilter, excludePropertyFromResultsFilter, excludeTenancyFromResultsFilter, PropertyDecomissionedFilter, propertyExcludeIdsFilter, propertyPortfolioFilter, TenancyDecomissionedFilter, TenancySoldFilter } from "~/helpers/apollo/apollo-portfolio-filter-helpers";
import { useQuery } from "@vue/apollo-composable";
import { getSetValue } from "~/helpers/portfolio/portfolio-category-helpers";
import { getOpexCategoryData, getPropertiesAverage, getPropertiesBudgetOpexData } from "~/helpers/portfolio/portfolio-opex-helpers";
import { getPropertyArea } from "~/helpers/portfolio/portfolio-property-helpers";
import { useOpexCategoriesQuery } from "./useOpexCategoriesQuery";
import { arrayLast } from "~/helpers/common-helpers";
import { useOpexPeriods } from "./useOpexPeriods";
export var useOpexTableData = function useOpexTableData(preferences, variables) {
  var areaUnit = ref();
  var currency = ref();
  var enabled = computed(function () {
    return !!variables.value.portfolioId || !!variables.value.categoryId && !!variables.value.propertyId;
  });
  var excludePropertyIds = computed(function () {
    return preferences.value.excludePropertyIds;
  });
  var periods = useOpexPeriods(preferences);
  var columns = periods.columns,
    from = periods.from,
    to = periods.to;
  var propsOpexFilters = useQueryFilters({
    budgetFilters: [budgetValidRangeFilter(computed(function () {
      return {
        from: from.value,
        to: to.value
      };
    }))],
    propertyFilters: [propertyPortfolioFilter(variables), propertyExcludeIdsFilter(computed(function () {
      return {
        excludeIds: excludePropertyIds.value
      };
    })), PropertyDecomissionedFilter, excludePropertyFromResultsFilter],
    tenancyFilters: [TenancySoldFilter, TenancyDecomissionedFilter, excludeTenancyFromResultsFilter]
  });
  var propertiesOpexQuery = useQuery(ResultsOpexPropertiesDocument, function () {
    return {
      propertyFilters: propsOpexFilters.value.value.propertyFilters,
      tenancyFilters: propsOpexFilters.value.value.tenancyFilters,
      budgetFilters: propsOpexFilters.value.value.budgetFilters,
      input: {
        categoryType: AssetManagementCategoryType.Opex,
        periodType: MetricPeriodType.Monthly,
        from: from.value,
        to: to.value
      },
      includeBudgets: true
    };
  }, function () {
    return {
      enabled: enabled.value
    };
  });
  var properties = computed(function () {
    var _propertiesOpexQuery$, _propertiesOpexQuery$2;
    return (_propertiesOpexQuery$ = (_propertiesOpexQuery$2 = propertiesOpexQuery.result.value) === null || _propertiesOpexQuery$2 === void 0 ? void 0 : _propertiesOpexQuery$2.assetManagementProperties.items) !== null && _propertiesOpexQuery$ !== void 0 ? _propertiesOpexQuery$ : [];
  });
  var budgetIds = computed(function () {
    var ids = new Set();
    var yearsCovered = new Set();
    columns.value.forEach(function (period) {
      var year = period.year();
      if (yearsCovered.has(year)) return;
      yearsCovered.add(year);
      properties.value.forEach(function (property) {
        var _property$assetManage, _property$assetManage2, _property$assetManage3;
        /**Note Sven: This needs to be changed if we want to handle budgets not conforming to a year */
        var budgets = (_property$assetManage = (_property$assetManage2 = property.assetManagementBudgets) === null || _property$assetManage2 === void 0 ? void 0 : (_property$assetManage3 = _property$assetManage2.items) === null || _property$assetManage3 === void 0 ? void 0 : _property$assetManage3.filter(function (b) {
          return moment.utc(b.validFrom).year() === year && moment.utc(b.validTo).year() === year;
        })) !== null && _property$assetManage !== void 0 ? _property$assetManage : [];
        var budget = budgets.reduce(function (a, b) {
          return !a || moment.utc(b.createdAt).isAfter(moment.utc(a.createdAt)) ? b : a;
        }, undefined);
        if (budget) ids.add(budget.id);
      });
    });
    return Array.from(ids);
  });
  var budgetFilters = useQueryFilters({
    filters: [function () {
      return {
        matches: [{
          id: [Operator.In, budgetIds.value]
        }]
      };
    }]
  });
  var budgetRecordsQuery = useQuery(ResultsOpexBudgetRecordsDocument, function () {
    return {
      filters: budgetFilters.value.value.filters
    };
  }, function () {
    return {
      enabled: enabled.value && !propertiesOpexQuery.error.value && !propertiesOpexQuery.loading.value && !!properties.value.length
    };
  });
  var budgets = computed(function () {
    var _budgetRecordsQuery$r, _budgetRecordsQuery$r2, _budgetRecordsQuery$r3;
    return (_budgetRecordsQuery$r = (_budgetRecordsQuery$r2 = budgetRecordsQuery.result.value) === null || _budgetRecordsQuery$r2 === void 0 ? void 0 : (_budgetRecordsQuery$r3 = _budgetRecordsQuery$r2.assetManagementBudgets) === null || _budgetRecordsQuery$r3 === void 0 ? void 0 : _budgetRecordsQuery$r3.items) !== null && _budgetRecordsQuery$r !== void 0 ? _budgetRecordsQuery$r : [];
  });
  var opexCategoriesQuery = useOpexCategoriesQuery();
  var opexCategories = computed(function () {
    var _opexCategoriesQuery$;
    var arr = (_opexCategoriesQuery$ = opexCategoriesQuery.result.value) !== null && _opexCategoriesQuery$ !== void 0 ? _opexCategoriesQuery$ : [];
    var categoryId = variables.value.categoryId;
    if (categoryId) arr = arr.filter(function (c) {
      return c.id === categoryId;
    });
    return arr;
  });
  var categoryIds = computed(function () {
    return new Set(opexCategories.value.map(function (c) {
      return c.id;
    }));
  });
  var averageOpexData = computed(function () {
    var opexData = getPropertiesAverage(properties.value);
    areaUnit.value = opexData.entityData.areaUnit;
    return opexData;
  });
  var averageData = computed(function () {
    var _preferences$value, _preferences$value$no, _preferences$value2;
    if (!columns.value || !opexCategories.value) return;
    var data = getOpexCategoryData(columns.value, averageOpexData.value, getPropertiesBudgetOpexData(properties.value, categoryIds.value, budgets.value), {
      includeZeroRows: (_preferences$value = preferences.value) === null || _preferences$value === void 0 ? void 0 : _preferences$value.includeZeroRows,
      normaliseBy: (_preferences$value$no = (_preferences$value2 = preferences.value) === null || _preferences$value2 === void 0 ? void 0 : _preferences$value2.normaliseBy) !== null && _preferences$value$no !== void 0 ? _preferences$value$no : "area",
      periodType: MetricPeriodType.Monthly
    }, opexCategories.value, true);
    return data;
  });
  var getPropertyBenchmarkData = function getPropertyBenchmarkData(categories, property, columns) {
    var _property$assetManage4, _property$assetManage5, _property$assetManage6, _preferences$value3, _preferences$value$no2, _preferences$value4;
    var _getPropertyArea = getPropertyArea(property),
      area = _getPropertyArea.area,
      areaUnit = _getPropertyArea.areaUnit;
    var opexData = {
      cells: property.categoryCells,
      entityData: {
        averageArea: area || 1,
        areaUnit: areaUnit,
        totalArea: area,
        totalTenancies: (_property$assetManage4 = (_property$assetManage5 = property.assetManagementTenancies) === null || _property$assetManage5 === void 0 ? void 0 : (_property$assetManage6 = _property$assetManage5.items) === null || _property$assetManage6 === void 0 ? void 0 : _property$assetManage6.length) !== null && _property$assetManage4 !== void 0 ? _property$assetManage4 : 0
      },
      entityCount: 1
    };
    var categoriesData = getOpexCategoryData(columns, opexData, getPropertiesBudgetOpexData([property], categoryIds.value, budgets.value), {
      includeZeroRows: (_preferences$value3 = preferences.value) === null || _preferences$value3 === void 0 ? void 0 : _preferences$value3.includeZeroRows,
      normaliseBy: (_preferences$value$no2 = (_preferences$value4 = preferences.value) === null || _preferences$value4 === void 0 ? void 0 : _preferences$value4.normaliseBy) !== null && _preferences$value$no2 !== void 0 ? _preferences$value$no2 : "area",
      periodType: MetricPeriodType.Monthly
    }, categories, true);
    return {
      property: property,
      categories: categoriesData,
      area: area
    };
  };
  var getRows = computed(function () {
    return function (rows) {
      var _arr$find;
      if (!opexCategories.value || !averageOpexData.value || !averageData.value) return [];
      var categories = new Map();
      var propertyOpexBenchmarks = rows.map(function (property) {
        return getPropertyBenchmarkData(opexCategories.value, property, columns.value);
      });
      for (var i = 0; i < propertyOpexBenchmarks.length; i++) {
        var propertyCategoryData = propertyOpexBenchmarks[i];
        var _loop = function _loop() {
          var _propertyCategoryData, _propertyCategoryData2, _propertyCategoryData3, _propertyCategoryData4, _propertyCategoryData5, _propertyCategoryData6;
          var categoryData = propertyCategoryData.categories[c];
          var categoryAverageData = averageData.value.find(function (row) {
            return row.id === categoryData.id;
          });
          var value = function value(data, key) {
            var _arrayLast$accumulate, _arrayLast;
            return (_arrayLast$accumulate = (_arrayLast = arrayLast(data.columns[key])) === null || _arrayLast === void 0 ? void 0 : _arrayLast.accumulated) !== null && _arrayLast$accumulate !== void 0 ? _arrayLast$accumulate : 0;
          };
          var averageNormal = preferences.value.normaliseBy === "area" ? averageOpexData.value.entityData.totalArea : preferences.value.normaliseBy === "tenancies" ? averageOpexData.value.entityData.totalTenancies : averageOpexData.value.entityCount;
          var group = getSetValue(categoryData.id, categories, function () {
            return {
              propertiesData: new Map(),
              id: categoryData.id,
              name: categoryData.name,
              currency: categoryData.currency,
              average: {
                area: averageOpexData.value.entityData.totalArea,
                tenancies: averageOpexData.value.entityData.totalTenancies,
                budgetTotal: value(categoryAverageData, "budget"),
                budgetNormal: value(categoryAverageData, "budget") / (averageNormal || 1),
                resultTotal: value(categoryAverageData, "result"),
                resultNormal: value(categoryAverageData, "result") / (averageNormal || 1),
                areaUnit: averageOpexData.value.entityData.areaUnit
              }
            };
          });
          var normal = preferences.value.normaliseBy === "area" ? propertyCategoryData.area : preferences.value.normaliseBy === "tenancies" ? (_propertyCategoryData = (_propertyCategoryData2 = propertyCategoryData.property.assetManagementTenancies) === null || _propertyCategoryData2 === void 0 ? void 0 : (_propertyCategoryData3 = _propertyCategoryData2.metadata) === null || _propertyCategoryData3 === void 0 ? void 0 : _propertyCategoryData3.totalCount) !== null && _propertyCategoryData !== void 0 ? _propertyCategoryData : 0 : 1;
          group.propertiesData.set(propertyCategoryData.property.id, {
            area: propertyCategoryData.area,
            tenancies: (_propertyCategoryData4 = (_propertyCategoryData5 = propertyCategoryData.property.assetManagementTenancies) === null || _propertyCategoryData5 === void 0 ? void 0 : (_propertyCategoryData6 = _propertyCategoryData5.metadata) === null || _propertyCategoryData6 === void 0 ? void 0 : _propertyCategoryData6.totalCount) !== null && _propertyCategoryData4 !== void 0 ? _propertyCategoryData4 : 0,
            budgetTotal: value(categoryData, "budget"),
            budgetNormal: value(categoryData, "budget") / (normal || 1),
            resultTotal: value(categoryData, "result"),
            resultNormal: value(categoryData, "result") / (normal || 1),
            areaUnit: averageOpexData.value.entityData.areaUnit
          });
        };
        for (var c = 0; c < propertyCategoryData.categories.length; c++) {
          _loop();
        }
      }
      var arr = Array.from(categories.values());
      currency.value = (_arr$find = arr.find(function (r) {
        return !!r.currency;
      })) === null || _arr$find === void 0 ? void 0 : _arr$find.currency;
      return arr;
    };
  });
  var tableRows = computed(function () {
    return getRows.value(properties.value);
  });
  var loading = computed(function () {
    return propertiesOpexQuery.loading.value || opexCategoriesQuery.loading.value || budgetRecordsQuery.loading.value;
  });
  return {
    currency: currency,
    areaUnit: areaUnit,
    loading: loading,
    tableRows: tableRows,
    columns: columns,
    properties: properties,
    periodType: MetricPeriodType.Monthly
  };
};